import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import SliderReviewsComponent from './slider_reviews_home'

function CreateRatings(p) {
  let ratings = []
  for (let i = 0; i < 5; ++i) {
    if (p.rating > i) {
      ratings.push(<span className="text-xl text-green mr-2" key={i}>&#9733;</span>)
    } else {
      ratings.push(<span className="text-xl text-diamond-30 mr-2" key={i}>&#9733;</span>)
    }
  }
  return (
    <div className="mt-1 mb-4 text-center">
      {ratings}
    </div>
  )
}

function GoogleReviews(p) {
  //console.log('p: ', p)
  return (
    <div>
      <div className="block sm:hidden">
        <SliderReviewsComponent slides={p} />
      </div>
      <div className="hidden sm:grid sm:grid-cols-3 lg:grid-cols-5 gap-12 sm:gap-6">
        {p.reviewItems.slice(0,5).map((entry) => {
          //console.log('entry: ', entry)
          let excerpt = entry.text.length > 150 ? entry.text.substring(0, 150) + "..." : entry.text;
          return (
            <div key={entry.author} className="font-poppins bg-diamond p-6 rounded-tl-[24px] rounded-tr-[24px] rounded-bl-[24px]">
              <figure>
                <StaticImage className="mx-auto w-8 h-8 block" src="../images/google_sm.png" alt="Google Review" />
              </figure>
              <CreateRatings rating={entry.rating} />
              <div className="prose prose-sm prose-p:font-medium text-diamond-10 leading-snug">
                <p>&ldquo;{excerpt}&rdquo;</p>
              </div>
              <figure className="mt-4">
                {entry.image.gatsbyImageData ? <GatsbyImage className="w-10 h-10 mx-auto rounded-full block" image={entry.image.gatsbyImageData} alt={entry.image.alt ?? ''} />
                : <StaticImage className="mx-auto w-8 h-8 block" src="../images/google_sm.png" alt="Google Review" />
                }
              </figure>
              <p className="mt-4 text-center text-sm font-medium text-diamond-10 leading-snug capitalize">{entry.author}</p>
              <p className="text-center text-xs lowercase font-medium text-diamond-10">({entry.time})</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const ReviewsCategory = () => (
  <StaticQuery
    query={graphql`
      {
        prismicGoogleReviews {
          data {
            google_reviews {
              time
              text
              rating
              image {
                gatsbyImageData
                alt
              }
              author
            }
            total_reviews
          }
        }
      }
    `} 
    render = { data => (
      <>
        <div className="w-full pt-16 text-center">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <div className="text-center font-poppins font-semibold text-diamond text-2xl pb-12 leading-tight">
              <h2>See what our <span className="bg-white px-3 py-1 text-orange">{data.prismicGoogleReviews.data.total_reviews}</span> L&C friends say about us!</h2>
            </div>
          </div>
        </div>
        <div className="w-full pb-16 bg-diamond-10">
          <div className="w-9/12 sm:w-10/12 mx-auto max-w-screen-xl">
            <GoogleReviews reviewItems={data.prismicGoogleReviews.data.google_reviews} />
          </div>
        </div>
      </>
    )}
  />
)

export default ReviewsCategory