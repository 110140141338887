// src/components/best_sellers.js

// Gatsby dependencies
import React, { useCallback, useEffect, useState } from "react";
import { GatsbyImage } from 'gatsby-plugin-image'

// Utility and component dependencies
import useEmblaCarousel from "embla-carousel-react";

const EmblaCarousel = ({ slides }) => {

  const [emblaRef, emblaApi] = useEmblaCarousel({ 
    loop: true,
    align: 'start',
  });

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setPrevBtnDisabled(!emblaApi.canScrollPrev())
      setNextBtnDisabled(!emblaApi.canScrollNext())
      setSelectedIndex(emblaApi.selectedScrollSnap())
    }
  }, [emblaApi]);

  const scrollTo = useCallback(
    (index) => {
      if (emblaApi) emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSelect);
      onSelect();
    }
  }, [emblaApi, onSelect]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        scrollPrev();
      } else if (e.key === 'ArrowRight') {
        scrollNext();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [scrollPrev, scrollNext]);

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  return (
    <div className="relative max-w-screen-xl mx-auto">
      <button className="z-30 absolute top-1/2 -translate-y-1/2 -left-12 cursor-pointer bg-diamond rounded-full p-3 text-white hover:text-white disabled:hover:text-white/25 disabled:text-white/25" onClick={scrollPrev}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
      </button>
      <button className="z-30 absolute top-1/2 -translate-y-1/2 -right-12 cursor-pointer bg-diamond rounded-full p-3 text-white hover:text-white disabled:hover:text-white/25 disabled:text-white/25" onClick={scrollNext}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
        </svg>
      </button>
      {/* Carousel viewport */}
      <div className="overflow-hidden relative" ref={emblaRef}>
        {/* Carousel container */}
        <div className="flex -ml-8">
          {/* Slides */}
          {slides.data.experience.map((entry, index) => {
            return (
              <div className="pl-8 flex-[0_0_100%] sm:flex-[0_0_50%] md:flex-[0_0_33.333%]" key={index}>
                <figure className="mx-auto w-32 h-32 bg-white rounded-full flex items-center">
                  <GatsbyImage className="w-24 h-24 block mx-auto" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} /> 
                </figure>
                <div className="font-poppins text-white text-xl font-semibold mt-12 mb-3">
                  <h3>{entry.heading1}</h3>
                </div>
                <div className="prose prose-p:font-poppins prose-p:font-medium prose-p:text-white prose-p:text-sm leading-snug">
                  <p>{entry.text1}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/* Simple dots navigation */}
      <div className="flex justify-center mt-4 mb-8 gap-2">
        {slides.data.best_sellers_entries.filter(entry => entry.hide === false).map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full transition-colors ${
              index === selectedIndex ? "bg-orange" : "bg-diamond-50"
            }`}
            onClick={() => scrollTo(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default EmblaCarousel;