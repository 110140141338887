// src/pages/index.js

// Gatsby dependencies
import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

// Utility and component dependencies
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from '../components/header'
import BestSellersComponent from '../components/slider_best_sellers'
import CustomisationOptionsComponent from '../components/slider_customisation_options'
import WhyComponent from '../components/slider_why'

// React hooks and custom components
import Reviews from '../components/reviews_home'

const HomePage = ({data}) => {
  const entry = data.prismicHomePage

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  if (!entry) return null
  return (
    <>
      {/* SEO and page structure components */}
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />

      {/* hero component */}
      <div className="w-full">
        <div className="w-full py-36 md:py-60 relative overflow-hidden">
          <div className="absolute inset-y-0 -right-48 lg:inset-0">
            {entry.data.image_page_link ? (
              entry.data.image_page_link.link_type === 'Document' ? (
                <Link to={linkResolver(entry.data.image_page_link)} className="w-full h-full block relative z-30">
                  <GatsbyImage 
                    className="w-full h-full object-fit" 
                    image={entry.data.image.gatsbyImageData} 
                    alt={entry.data.image.alt ?? ''} 
                  />
                </Link>
              ) : (
                (entry.data.image_page_link.link_type === 'Web' || entry.data.image_page_link.link_type === 'Media') ? (
                  <a 
                    href={entry.data.image_page_link.url} 
                    target={entry.data.image_page_link.target || undefined} 
                    rel={entry.data.image_page_link.target === "_blank" ? "noopener noreferrer" : undefined} 
                    className="w-full h-full block relative z-30"
                  >
                    <GatsbyImage 
                      className="w-full h-full object-fit" 
                      image={entry.data.image.gatsbyImageData} 
                      alt={entry.data.image.alt ?? ''} 
                    />
                  </a>
                ) : (
                  <GatsbyImage 
                    className="w-full h-full object-fit" 
                    image={entry.data.image.gatsbyImageData} 
                    alt={entry.data.image.alt ?? ''} 
                  />
                )
              )
            ) : (
              <GatsbyImage 
                className="w-full h-full object-fit" 
                image={entry.data.image.gatsbyImageData} 
                alt={entry.data.image.alt ?? ''} 
              />
            )}
          </div>
          <div className="hidden md:block absolute inset-0 z-20 pointer-events-none">
            <div className="flex items-end h-full">
              <div className="w-8/12 md:w-6/12">
                &nbsp;
              </div>
              <div className="w-3/12 md:w-6/12 h-full">
                <div className="w-full h-full block bg-gradient-to-l from-black to-transparent">
                  &nbsp;
                </div>
              </div>
              <div className="w-1/12 h-full bg-black">
                &nbsp;
              </div>
            </div>
          </div>
          <div className="absolute h-96 w-full bottom-0 overflow-hidden z-20 pointer-events-none">
            <div className="absolute -right-8 md:-right-16 bottom-16 opacity-40">
              <img className="w-36 h-24 md:w-72 md:h-60 -rotate-45" src="/img/paw_print.svg" alt="" />  
            </div>
          </div>
          <div className="z-30 w-full lg:w-9/12 mx-auto relative pointer-events-none">
            <div className="flex-none md:flex justify-between items-center">
              <div className="w-full md:w-6/12">
                &nbsp;
              </div>
              <div className="w-full md:w-6/12 text-center md:text-left">
                <div className="pt-20 pb-24 lg:py-24 relative flex items-center">
                  <div className="z-20 absolute w-full md:w-none">
                    <div className="font-poppins leading-tight text-2xl md:text-3xl text-white font-semibold">
                      <h1>
                        <span className="bg-orange px-3 py-1 font-bold leading-tight inline-block">{entry.data.tag}</span>
                      </h1>
                      <p className="mt-72 lg:mt-3 ml-3 w-11/12 lg:w-full text-xl lg:text-3xl">{entry.data.heading}</p>
                    </div>
                    <div className="mt-0 lg:mt-6 text-lg md:text-2xl text-white font-poppins font-medium">
                      <div className="relative border border-white px-3 py-1 lg:py-3 inline-block pointer-events-auto">
                        <div className="absolute -inset-0 bg-diamond/30 mix-blend-multiply" />
                        <div className="relative">
                          <Link to="/dogs/">Shop Dogs</Link>
                        </div>
                      </div>
                      <div className="relative border border-white px-3 py-1 lg:py-3 inline-block ml-3 mt-3 sm:mt-0 pointer-events-auto">
                        <div className="absolute -inset-0 bg-diamond/30 mix-blend-multiply" />
                        <div className="relative">
                          <Link to="/cats/">Shop Cats</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* why Led & Collared component */}
        <div className="w-full bg-white">
          <div className="w-full bg-diamond rounded-br-[133px]">
            <div className="w-full pt-16 pb-12 text-center">
              <div className="w-10/12 mx-auto max-w-screen-xl">
                <h2 className="font-poppins text-white font-semibold text-2xl leading-tight">{entry.data.experience_heading}</h2>
              </div>
            </div>
            <div className="w-full pb-16 text-center">
              <div className="w-9/12 sm:w-10/12 mx-auto max-w-screen-xl">
                <div className="block sm:hidden">
                  <WhyComponent slides={entry} />
                </div>
                <div className="hidden sm:grid sm:grid-cols-3 lg:grid-cols-5 gap-12">
                  {entry.data.experience.map((entry,i) => {
                    return (
                      <div key={i}>
                        <figure className="mx-auto w-32 h-32 bg-white rounded-full flex items-center">
                          <GatsbyImage className="w-24 h-24 block mx-auto" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} /> 
                        </figure>
                        <div className="font-poppins text-white text-xl font-semibold mt-12 mb-3">
                          <h3>{entry.heading1}</h3>
                        </div>
                        <div className="prose prose-p:font-poppins prose-p:font-medium prose-p:text-white prose-p:text-sm leading-snug">
                          <p>{entry.text1}</p>
                        </div>
                      </div>
                    )
                  })}       
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Shop categories component */}
        <div className="w-full py-16 text-center relative">
          <div className="absolute z-10 -left-12 bottom-24 opacity-10">
            <img className="w-72 h-60 rotate-45" src="/img/paw_print.svg" alt="" />  
          </div>
          <div className="w-9/12 mx-auto max-w-screen-xl">
            <div className="grid grid-cols-1 sm:grid-cols-2 items-center gap-24">
              <div>
                <Link to="/dogs/">
                  <div className="font-poppins text-orange font-semibold text-2xl sm:text-3xl mb-6 leading-tight">
                    <h2>Shop for my Dog</h2>
                  </div>
                  <div className="relative aspect-w-4 aspect-h-3">
                    <figure className="absolute inset-0 z-20">
                      <GatsbyImage className="aspect-w-4 aspect-h-3 rounded-bl-[27px] rounded-tl-[27px] rounded-tr-[27px] w-full h-full object-cover block" image={entry.data.shop_dogs_image.gatsbyImageData} alt={entry.data.shop_dogs_image.alt ?? ''} />
                    </figure>
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/cats/">
                  <div className="font-poppins text-diamond-80 font-semibold text-2xl sm:text-3xl mb-6 leading-tight">
                    <h2>Shop for my Cat</h2>
                  </div>
                  <div className="relative aspect-w-4 aspect-h-3">
                    <figure className="absolute inset-0 z-20">
                      <GatsbyImage className="aspect-w-4 aspect-h-3 rounded-bl-[27px] rounded-tl-[27px] rounded-tr-[27px] w-full h-full object-cover block" image={entry.data.shop_cats_image.gatsbyImageData} alt={entry.data.shop_cats_image.alt ?? ''} />
                    </figure>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Our guarantee component */}
        <div className="w-full bg-white">
          <div className="w-full bg-orange rounded-tl-[133px]">
            <div className="w-full pt-16 pb-6 lg:pb-6 text-center">
              <div className="w-10/12 sm:w-10/12 mx-auto max-w-screen-xl">
                <h2 className="font-poppins text-white font-semibold text-2xl leading-tight">
                  {entry.data.guarantee_heading}
                </h2>
              </div>
            </div>
            <div className="w-full pb-16">
              <div className="w-9/12 sm:w-10/12 mx-auto max-w-screen-xl">
                <div className="hidden sm:block sm:text-left flex-none sm:flex justify-between items-center gap-6 md:gap-0 lg:gap-12 xl:gap-6">
                  <div className="flex justify-center w-full sm:w-4/12 lg:w-2/12 mb-12 sm:mb-0">
                    <figure className="flex items-center justify-center w-36 h-36 lg:w-40 lg:h-40 bg-white p-3 rounded-full">
                      <GatsbyImage className="w-36 h-36 lg:w-40 lg:h-40 inline-block mx-auto bg-white p-3 rounded-full" image={entry.data.guarantee_image.gatsbyImageData} alt={entry.data.guarantee_image.alt ?? ''} /> 
                    </figure>
                  </div>
                  <div className="w-full sm:w-8/12 lg:w-10/12">
                    <div className="font-poppins max-w-none prose prose-h2:text-white prose-h2:font-semibold prose-p:font-medium prose-p:text-white prose-a:text-white prose-a:hover:text-white leading-snug">
                      <PrismicRichText linkResolver={linkResolver} field={entry.data.guarantee_text.richText} />
                    </div>
                  </div>
                </div>
                <div className="block sm:hidden">
                  <div className="float-left mr-6 mb-3">
                    <figure className="flex items-center justify-center w-24 h-24 bg-white p-3 rounded-full">
                      <GatsbyImage className="w-24 h-24 inline-block mx-auto bg-white p-3 rounded-full" image={entry.data.guarantee_image.gatsbyImageData} alt={entry.data.guarantee_image.alt ?? ''} /> 
                    </figure>
                  </div>
                  <div className="font-poppins max-w-none prose prose-h2:text-white prose-h2:font-semibold prose-p:font-medium prose-p:text-white prose-a:text-white prose-a:hover:text-white leading-snug">
                    <PrismicRichText linkResolver={linkResolver} field={entry.data.guarantee_text.richText} />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* Best sellers component */}
        <div className="w-full mb-8 bg-white">
          <div className="w-full py-6 lg:py-8 text-center">
            <div className="w-10/12 mx-auto max-w-screen-xl">
              <div className="flex flex-col-reverse gap-4 md:flex-row md:gap-0 justify-center md:justify-between items-center">
                <div className="w-full md:w-1/3">
                  {entry.data.best_sellers_tag_text && 
                    <div className="flex flex-row justify-center md:justify-start items-center gap-2">
                      <div className="w-12 h-12 rounded-full p-1 bg-diamond">
                        <StaticImage className="w-full h-full object-cover block" src="../images/personalisation_white.png" alt="" />
                      </div>
                      <div className="font-poppins text-diamond font-semibold leading-tight">
                        {entry.data.best_sellers_tag_text}
                      </div>
                    </div>
                  }
                </div>
                <div className="w-full md:w-1/3">
                  <h2 className="font-poppins text-diamond font-semibold text-2xl leading-tight">
                    {entry.data.best_sellers_heading}
                  </h2>
                </div>
                <div className="w-1/3">&nbsp;</div>
              </div>
            </div>
          </div>
          <div className="w-9/12 sm:w-10/12 mx-auto max-w-screen-xl">
            <div className="block sm:hidden">
              <BestSellersComponent slides={entry} />
            </div>
            <div className="hidden sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {entry.data.best_sellers_entries.map((entry, i) => {
                return (
                  <div key={i}>
                    {entry.hide === false ?
                    <div className="p-8 rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px] border-2 border-diamond-50">
                      <Link to={linkResolver(entry.page_link)} className="flex flex-col h-full">
                        {/* Image section with fixed height */}
                        <div className="h-60">  {/* This fixed height container ensures alignment */}
                          <figure className="h-full">
                            <div className="w-full h-full flex items-center justify-center"> {/* Remove aspect-square */}
                              <GatsbyImage 
                                className="object-contain max-w-full max-h-full block rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px]" 
                                image={entry.image1.gatsbyImageData} 
                                alt={entry.image1.alt ?? ''} 
                              />
                            </div>
                          </figure>
                        </div>
                        {/* Content section */}
                        <div className="flex flex-col flex-1">
                          <div className="w-full font-poppins text-orange font-semibold text-xl leading-tight mt-6">
                            <h3>{entry.heading1} &#62;</h3>
                          </div>
                          <div className="w-full font-poppins font-medium text-sm text-diamond-80 mt-3 leading-snug">
                            <PrismicRichText linkResolver={linkResolver} field={entry.text.richText} />
                          </div>
                          <div className="w-full font-poppins font-medium text-lg text-diamond-80 mt-3 mb-6 leading-snug">
                            <p>From {currency.format(entry.price) && currency.format(entry.price)} NZD</p>
                          </div>

                          <div className="mt-auto flex items-end justify-end">
                            <div className="w-full flex flex-row justify-between">
                              <div className="w-2/3">
                                {entry.label !== 'None' && (
                                  <div 
                                    className={`
                                      inline-block border-2 rounded-full px-4 py-2 font-poppins text-sm font-medium
                                      ${entry.label === 'Best Seller' || entry.label === 'On Sale' 
                                        ? 'border-diamond text-diamond' 
                                        : entry.label === 'New Product' 
                                          ? 'border-orange text-orange'
                                          : entry.label === 'New Colour' 
                                            ? 'border-pink text-pink'
                                            : entry.label === 'New Features'
                                              ? 'border-purple text-purple'
                                              : 'border-diamond text-diamond'
                                      }
                                    `}
                                  >
                                    {entry.label}
                                  </div>
                                )}
                              </div>
                              <div className="w-1/3 flex justify-end">
                                {entry.personalisation !== false ? 
                                  <div className="w-10 h-10 rounded-full p-1 bg-diamond">
                                    <StaticImage className="w-full h-full object-cover block" src="../images/personalisation_white.png" alt="" />
                                  </div>
                                  :
                                  <div className="w-10 h-10 rounded-full p-1 bg-transparent">
                                    &nbsp;
                                  </div>
                                }
                              </div>
                            </div>
                          </div>

                        </div>
                      </Link>
                    </div>
                    : ''}
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        {/* About us component */}
        <div className="w-full bg-diamond">
          <div className="w-full bg-diamond-10 rounded-br-[133px]">
            <div className="w-full mx-auto">
              <div className="flex-none lg:flex justify-between gap-16">
                <div className="w-full lg:w-6/12">
                  <GatsbyImage style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} className="w-full h-full block" image={entry.data.about_image.gatsbyImageData} alt={entry.data.about_image.alt ?? ''} /> 
                </div>
                <div className="w-full lg:w-6/12">
                  <div className="px-12 lg:px-0 py-16 lg:pr-16">
                    
                    <h3 className="font-poppins bg-orange px-3 py-1 text-white font-poppins font-bold text-2xl md:text-3xl leading-tight inline-block">{entry.data.about_tag}</h3>
                    <h2 className="font-poppins text-orange font-semibold text-2xl md:text-3xl leading-tight mb-6">{entry.data.about_heading}</h2>
                    
                    <div className="font-poppins prose prose-h2:text-diamond prose-h2:font-semibold prose-p:font-medium prose-p:text-diamond-80 leading-snug">
                      <PrismicRichText linkResolver={linkResolver} field={entry.data.about_text.richText} />
                    </div>
                    <Link to='/about/' className="font-poppins text-diamond-80 text-lg text-orange visited:text-orange mt-6 inline-block">
                      Learn more &gt;
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Customisation component */}
        <div className="w-full pt-16 pb-6 sm:pb-12 bg-diamond text-center">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <h2 className="font-poppins text-white font-semibold text-2xl leading-tight">
              {entry.data.customisation_heading}
            </h2>
          </div>
        </div>
        <div className="w-full pb-16 bg-diamond text-center">
          <div className="w-9/12 sm:w-10/12 mx-auto max-w-screen-xl">
            <div className="block sm:hidden">
              <CustomisationOptionsComponent slides={entry} />
            </div>
            <div className="hidden sm:grid sm:grid-cols-3 lg:grid-cols-5 gap-12">
              {entry.data.customisation.map((entry, index) => {
                return (
                  <div key={index}>
                    <figure>
                      <GatsbyImage className="w-32 h-32 block mx-auto" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} /> 
                    </figure>
                    <div className="font-poppins text-white text-xl font-semibold mt-12 mb-3 leading-tight">
                      <h3>{entry.heading1}</h3>
                    </div>
                    <div className="prose font-poppins prose-p:font-medium prose-p:text-white prose-p:text-sm text-snug">
                      <p>{entry.text1}</p>
                    </div>
                  </div>
                )
              })}            
            </div>
          </div>
        </div>
 
        {/* Product component */}
        <div className="w-full py-16">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <div className="flex-none md:flex justify-between gap-16">
              <div className="w-full md:w-6/12">
                <GatsbyImage className="object-scale-down w-full h-full block" image={entry.data.product_image.gatsbyImageData} alt={entry.data.product_image.alt ?? ''} />
              </div>
              <div className="w-full md:w-6/12 pt-24 md:pt-0 text-center md:text-left">
                <h3 className="font-poppins bg-orange px-3 py-1 text-white font-poppins font-bold text-xl mb-6 inline-block">{entry.data.product_tag}</h3>
                <h2 className="font-poppins text-diamond font-semibold text-3xl mb-6 leading-tight">{entry.data.product_heading}</h2>
                <div className="prose font-poppins prose-h2:font-semibold prose-p:font-medium leading-snug">
                  <PrismicRichText linkResolver={linkResolver} field={entry.data.product_text.richText} />
                </div>
                <Link to={linkResolver(entry.data.product_page_link)} className="font-poppins text-diamond-80 text-lg text-orange visited:text-orange mt-6 inline-block">
                  Learn more &gt;
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Reviews component */}
        <div className="w-full rounded-tl-[133px] bg-diamond-10">
          <Reviews />
        </div>

      </div>
      <Footer />      
    </>
  )
}

export const query = graphql`
query {
  prismicHomePage {
    data {
      page_title
      meta_description
      tag
      heading
      image {
        alt
        gatsbyImageData
      }
      image_page_link {
        url
        uid
        type
        link_type
        target
      }
      experience_heading
      experience {
        image1 {
          alt
          gatsbyImageData
        }
        heading1
        text1
      }
      shop_dogs_image {
        alt
        gatsbyImageData
      }
      shop_cats_image {
        alt
        gatsbyImageData
      }
      process_heading
      process {
        image1 {
          alt
          gatsbyImageData
        }
        heading1
        text1
      }
      about_tag
      about_image {
        alt
        gatsbyImageData
      }
      about_heading
      about_text {
        richText
      }
      guarantee_heading
      guarantee_image {
        alt
        gatsbyImageData
      }
      guarantee_text {
        richText
      }
      best_sellers_heading
      best_sellers_tag_text
      best_sellers_entries {
        hide
        label
        heading1
        image1 {
          alt
          gatsbyImageData
        }
        text {
          richText
        }
        page_link {
          url
          uid
          type
          link_type
        }
        personalisation
        price
      }
      customisation_heading
      customisation {
        image1 {
          alt
          gatsbyImageData
        }
        heading1
        text1
      }
      product_tag
      product_heading
      product_text {
        richText
      }
      product_page_link {
        url
        uid
        type
        link_type
      }
      product_image {
        alt
        gatsbyImageData
      }
      friends_heading_left
      friends_number
      friends_heading_right
      friends {
        heading1
        image1 {
          alt
          gatsbyImageData
        }
        quote1
        author1
      }
    }
  }
}
`

export default HomePage
