// src/components/best_sellers.js

// Gatsby dependencies
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "gatsby"
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

// Utility and component dependencies
import { linkResolver } from '../utils/link_resolver'
import useEmblaCarousel from "embla-carousel-react";

const EmblaCarousel = ({ slides }) => {

  const [emblaRef, emblaApi] = useEmblaCarousel({ 
    loop: true,
    align: 'start',
  });

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setPrevBtnDisabled(!emblaApi.canScrollPrev())
      setNextBtnDisabled(!emblaApi.canScrollNext())
      setSelectedIndex(emblaApi.selectedScrollSnap())
    }
  }, [emblaApi]);

  const scrollTo = useCallback(
    (index) => {
      if (emblaApi) emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSelect);
      onSelect();
    }
  }, [emblaApi, onSelect]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        scrollPrev();
      } else if (e.key === 'ArrowRight') {
        scrollNext();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [scrollPrev, scrollNext]);

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  return (
    <div className="relative max-w-screen-xl mx-auto">
      <button className="z-30 absolute top-1/2 -translate-y-1/2 -left-12 cursor-pointer bg-white rounded-full p-3 text-orange hover:text-orange disabled:hover:text-orange/25 disabled:text-orange/25" onClick={scrollPrev}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
      </button>
      <button className="z-30 absolute top-1/2 -translate-y-1/2 -right-12 cursor-pointer bg-white rounded-full p-3 text-orange hover:text-orange disabled:hover:text-orange/25 disabled:text-orange/25" onClick={scrollNext}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
        </svg>
      </button>
      {/* Carousel viewport */}
      <div className="overflow-hidden relative" ref={emblaRef}>
        {/* Carousel container */}
        <div className="flex -ml-8">
          {/* Slides */}
          {slides.data.best_sellers_entries.map((entry, index) => {
            return (
              <div className="pl-8 flex-[0_0_100%] sm:flex-[0_0_50%] md:flex-[0_0_33.333%]" key={index}>
                {entry.hide === false ?
                <div className="p-8 rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px] border-2 border-diamond-50">
                  <Link to={linkResolver(entry.page_link)} className="flex flex-col h-full">
                    {/* Image section with fixed height */}
                    <div className="h-60">  {/* This fixed height container ensures alignment */}
                      <figure className="h-full">
                        <div className="w-full h-full flex items-center justify-center"> {/* Remove aspect-square */}
                          <GatsbyImage 
                            className="object-contain max-w-full max-h-full block rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px]" 
                            image={entry.image1.gatsbyImageData} 
                            alt={entry.image1.alt ?? ''} 
                          />
                        </div>
                      </figure>
                    </div>
                    {/* Content section */}
                    <div className="flex flex-col flex-1">
                      <div className="w-full font-poppins text-orange font-semibold text-xl leading-tight mt-6">
                        <h3>{entry.heading1} &#62;</h3>
                      </div>
                      <div className="w-full font-poppins font-medium text-sm text-diamond-80 mt-3 leading-snug">
                        <PrismicRichText linkResolver={linkResolver} field={entry.text.richText} />
                      </div>
                      <div className="w-full font-poppins font-medium text-lg text-diamond-80 mt-3 mb-6 leading-snug">
                        <p>From {currency.format(entry.price) && currency.format(entry.price)} NZD</p>
                      </div>

                      <div className="mt-auto flex items-end justify-end">
                        <div className="w-full flex flex-row justify-between">
                          <div className="w-2/3">
                            {entry.label !== 'None' && (
                              <div 
                                className={`
                                  leading-tight inline-block border-2 rounded-full px-4 py-2 font-poppins text-sm font-medium
                                  ${entry.label === 'Best Seller' || entry.label === 'On Sale' 
                                    ? 'border-diamond text-diamond' 
                                    : entry.label === 'New Product' 
                                      ? 'border-orange text-orange'
                                      : entry.label === 'New Colour' 
                                        ? 'border-pink text-pink'
                                        : entry.label === 'New Features'
                                          ? 'border-purple text-purple'
                                          : 'border-diamond text-diamond'
                                  }
                                `}
                              >
                                {entry.label}
                              </div>
                            )}
                          </div>
                          <div className="w-1/3 flex justify-end">
                            {entry.personalisation !== false ? 
                              <div className="w-10 h-10 rounded-full p-1 bg-diamond">
                                <StaticImage className="w-full h-full object-cover block" src="../images/personalisation_white.png" alt="" />
                              </div>
                              :
                              <div className="w-10 h-10 rounded-full p-1 bg-transparent">
                                &nbsp;
                              </div>
                            }
                          </div>
                        </div>
                      </div>

                    </div>
                  </Link>
                </div>
                : ''}
              </div>
            )
          })}
        </div>
      </div>
      {/* Simple dots navigation */}
      <div className="flex justify-center mt-4 mb-8 gap-2">
        {slides.data.best_sellers_entries.filter(entry => entry.hide === false).map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full transition-colors ${
              index === selectedIndex ? "bg-orange" : "bg-diamond-50"
            }`}
            onClick={() => scrollTo(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default EmblaCarousel;